import { Button, Card, Grid, Input, Loading } from "@nextui-org/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateRecord = ({ refresh }) => {
  const token = useSelector((state) => state.invtry.token);
  const [input, setInput] = useState("");
  const [amt, setAmt] = useState("");
  const [loading, setLoading] = useState(false);

  const handler = (type) => {
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      fetch("/create-record", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ input, amount: amt, type }),
      })
        .then((res) => {
          return res.json();
        })
        .then((blob) => {
          if (!blob?.success) {
            // add this condition
            setLoading(false);
            return toast("An error occured!", {
              position: "top-right",
              autoClose: 1000,
              type: "error",
            });
          }

          refresh();
          setInput("");
          setAmt("");
          setLoading(false);
          return toast("Success", {
            position: "top-right",
            autoClose: 1000,
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return toast("An error occured!", {
            position: "top-right",
            autoClose: 1000,
            type: "error",
          });
        });
    }
  };

  return (
    <>
      <Card css={{ maxW: 750, p: 5 }} variant="bordered">
        <Grid.Container gap={1} justify="center" css={{ maxW: 750 }}>
          <Grid xs={12} sm={6} md={5}>
            <Input
              value={input}
              onChange={(e) => setInput(e.target.value)}
              bordered
              fullWidth
              placeholder="Input"
              type="text"
            />
          </Grid>
          <Grid xs={4} sm={4} md={3}>
            <Input
              value={amt}
              onChange={(e) => setAmt(e.target.value)}
              bordered
              fullWidth
              placeholder="Amount"
              type="number"
            />
          </Grid>
          <Grid xs={4} md={2}>
            <Button
              css={{ w: "100%" }}
              auto
              color={"warning"}
              disabled={loading || input === "" || amt === ""}
              onClick={() => handler("purchase")}
            >
              {loading ? <Loading type="spinner" /> : "Purchase"}
            </Button>
          </Grid>
          <Grid xs={4} md={2}>
            <Button
              css={{ w: "100%" }}
              auto
              color={"error"}
              disabled={loading || input === "" || amt === ""}
              onClick={() => handler("sale")}
            >
              {loading ? <Loading type="spinner" /> : "Sale"}
            </Button>
          </Grid>
        </Grid.Container>
      </Card>
      <ToastContainer />
    </>
  );
};

export default CreateRecord;
