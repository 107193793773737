import { createSlice } from "@reduxjs/toolkit";

let startDate = new Date(new Date().getFullYear(), 0, 1);

const initialInventory = {
  storedIphones: [],
  storedIpods: [],
  storedWatches: [],
  storedReports: [],
  storedUdhars: [],
  dateGte: new Date(startDate).toISOString().substring(0, 10),
  dateLte: new Date().toISOString().substring(0, 10),
  isAuth: false,
  token: "",
  level: "",
  manageItems: {},
};

const inventorySlice = createSlice({
  name: "invtry",
  initialState: initialInventory,
  reducers: {
    setStoredIphones(state, action) {
      state.storedIphones = action.payload;
    },
    setStoredIpods(state, action) {
      state.storedIpods = action.payload;
    },
    setStoredWatches(state, action) {
      state.storedWatches = action.payload;
    },
    setStoredReports(state, action) {
      state.storedReports = action.payload;
    },
    setStoredHistory(state, action) {
      state.storedHistory = action.payload;
    },
    setStoredHistoryAdj(state, action) {
      state.storedHistoryAdj = action.payload;
    },
    setStoredUdhars(state, action) {
      state.storedUdhars = action.payload;
    },
    setGte(state, action) {
      state.dateGte = action.payload;
    },
    setLte(state, action) {
      state.dateLte = action.payload;
    },
    setManageItems(state, action) {
      state.manageItems = action.payload;
    },
    setAuth(state, action) {
      state.isAuth = true;
      state.token = action.payload.token;
      state.level = action?.payload.level;
    },
    revokeAuth(state, action) {
      state.isAuth = false;
      state.token = "";
      state.level = "";
    },
  },
});

export const invtryActions = inventorySlice.actions;

export default inventorySlice.reducer;
