import { Button, Grid, Input, Loading, Spinner, Text } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { TbArrowBackUp } from "react-icons/tb";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useInput2 } from "../../hooks/useInput";
import Inputs from "./inputs";
import Stock from "./ipods";
import Variants from "./variants";

const EditProduct = () => {
  const token = useSelector((state) => state.invtry.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState({});
  const [type, setType] = useState("iPhone");
  const [variants, setVariants] = useState([]);
  const router = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  let t = searchParams.get("type");

  const { input: name } = useInput2((v) => v !== "", "");
  const { input: link } = useInput2((v) => v !== "", "");
  const { input: desc } = useInput2((v) => v !== "", "");
  const { input: quantity } = useInput2((v) => v > 0, "");
  const { input: price } = useInput2((v) => v > 0, "");
  const { input: myPrice } = useInput2((v) => v > 0, "");
  const { input: stock } = useInput2((v) => v !== "", "");

  const load = () => {
    setLoading(true);
    setError(false);
    fetch("/get-single-product", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, type: t }),
    })
      .then((res) => res.json())
      .then((d) => {
        console.log(d);
        setData(d.item);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  const loadHandler = async () => {
    setLoading(true);
    setError(false);
    let doc = {};

    if (type === "iPod") {
      doc = {
        basic: {
          name: name.inputValue,
          link: link.inputValue,
          short_desc: desc.inputValue,
          quantity: quantity.inputValue,
          isInStock: true,
          price: price.inputValue,
          my_price: myPrice.inputValue,
        },
      };
    } else {
      doc = {
        basic: {
          name: name.inputValue,
          link: link.inputValue,
          short_desc: desc.inputValue,
        },
        variants: variants,
      };
    }

    await fetch("/edit-product", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...doc, type, id }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setError(true);
          return setLoading(false);
        }

        window.alert("SUCCESS");
        router("/");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (data !== undefined && data !== null) {
      name.setInput(data.name);
      link.setInput(data.link);
      desc.setInput(data.short_desc);
      setType(t);
      if (t === "iPod") {
        quantity.setInput(data.quantity);
        price.setInput(data.price);
        myPrice.setInput(data.my_price);
      } else {
        setVariants(data.variants);
      }
    }
  }, [data]);

  return (
    <>
      <motion.div className="header">
        <Link to="/" className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>Edit Product</Text>
        <div className="headerLink">
          <Button
            auto
            size={"sm"}
            disabled={
              !name.isValid ||
              (type === "iPod"
                ? !quantity.isValid || !price.isValid || !myPrice.isValid
                : variants?.length <= 0) ||
              loading
            }
            onClick={loadHandler}
          >
            {loading ? <Loading type="spinner" color={"error"} /> : "Save"}
          </Button>
        </div>
      </motion.div>
      <motion.div className="qtyContainer alignDivCenter">
        <Grid.Container gap={1} css={{ maxW: 775 }}>
          <Grid xs={12}>
            <Text h5>Basic Details</Text>
          </Grid>
          <Grid
            xs={12}
            md={4}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: 0,
            }}
          >
            <div className="selectCustom">
              <select
                id="select"
                value={type}
                onChange={(event) => setType(event.target.value)}
                aria-label="Select Category"
                defaultValue={""}
                style={{ border: "none", width: "100%" }}
              >
                <option value={"iPhone"}>iPhone</option>
                <option value={"iPod"}>iPod</option>
                <option value={"iWatch"}>iWatch</option>
              </select>
            </div>
          </Grid>
          <Inputs name={name} link={link} desc={desc} />
          {type === "iPod" && (
            <>
              <Grid xs={12} css={{ mt: 20 }}>
                <Text h5>Price & Stock</Text>
              </Grid>
              <Stock
                quantity={quantity}
                price={price}
                myPrice={myPrice}
                stock={stock}
              />
            </>
          )}
          {type !== "iPod" && (
            <>
              <Grid xs={12} css={{ mt: 20 }}>
                <Text h5>Variants</Text>
              </Grid>
              <Variants
                variants={variants}
                setVariants={setVariants}
                type={type}
              />
            </>
          )}
        </Grid.Container>
      </motion.div>
    </>
  );
};

export default EditProduct;
