import { Loading, Spinner, Text } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import TopSection from "./TopSection";
import { useSelector } from "react-redux";
import HistoryTable from "./list";
import ExpensesTable from "./expenselist";
import Print from "./print";
import Message from "./message";

const DailyPdf = () => {
  const token = useSelector((state) => state.invtry.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [data, setData] = useState({
    orders: [],
    expenses: [],
  });

  const loadHandler = async () => {
    setLoading(true);
    setError(false);
    await fetch("/get-pdf-data/" + date, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setError(true);
          return setLoading(false);
        }

        setData(data?.payload);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  useEffect(() => {
    loadHandler();
  }, [date]);

  return (
    <>
      <motion.div className="header">
        <Link to="/" className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>Daily PDF</Text>
        {loading ? (
          <div className="headerLink">
            <Loading type="spinner" color={"error"} />
          </div>
        ) : (
          <div style={{ display: "flex", gap: "5px" }}>
            <Message data={data} date={date} />
            <Print data={data} date={date} />
          </div>
        )}
      </motion.div>
      <motion.div className="qtyContainer alignDivCenter">
        <TopSection date={date} setDate={setDate} />
        <HistoryTable list={data?.orders} />
        <ExpensesTable list={data?.expenses} refetch={loadHandler} />
      </motion.div>
    </>
  );
};

export default DailyPdf;
