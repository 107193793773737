import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { forwardRef, useState } from "react";
import { Item } from "./items";
import { Badge, Text } from "@nextui-org/react";
import { TbArrowUpCircle, TbEditCircle, TbGridDots } from "react-icons/tb";

const SortableList = ({ items, setItems, drag }) => {
  const [activeId, setActiveId] = useState(null);
  const [activeData, setActiveData] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  let sorted = [...items];

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={sorted} strategy={verticalListSortingStrategy}>
        {sorted?.map((doc) => {
          return <Item key={doc._id} doc={doc} id={doc._id} drag={drag} />;
        })}
      </SortableContext>
      <DragOverlay>
        {activeId ? <DragItem id={activeId} doc={activeData} /> : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event) {
    // console.log(event);
    const { active } = event;
    setActiveId(active.id);
    setActiveData(active?.data?.current);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      let copy = [...items];
      const oldIndex = items.findIndex((doc) => doc._id === active.id);
      const newIndex = items.findIndex((doc) => doc._id === over.id);

      let doc = arrayMove(copy, oldIndex, newIndex);
      setItems(doc);
    }
  }
};

export const DragItem = forwardRef(({ doc, ...props }, ref) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <div
        style={{
          width: "100%",
          minWidth: 250,
          padding: 10,
          border: "1px solid #f2e580",
          borderRadius: 12,
          background: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: 5 }}>
            <div
              style={{
                height: "100%",
                width: 30,
                height: 30,
                backgroundColor: "#f2e580",
                borderRadius: 12,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TbGridDots />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: 500, lineHeight: 1 }}>
                {doc.name}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1,
                  opacity: 0.7,
                }}
              >
                &#8377; {(doc.price ?? 0).toFixed(2)}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <Badge>{doc?.variant}</Badge>
            <div
              style={{
                background: "#f06225",
                borderRadius: 12,
                width: 30,
                height: 30,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              //   onClick={() => (edit ? setEdit(false) : setEdit(true))}
            >
              {edit ? <TbArrowUpCircle /> : <TbEditCircle />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default SortableList;
