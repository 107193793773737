import { Grid, Input } from "@nextui-org/react";

const Stock = ({ quantity, price, myPrice, stock }) => {
  return (
    <>
      <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="Quantity"
          placeholder="Quantity"
          bordered
          value={quantity.inputValue}
          onChange={quantity.inputHandler}
          onBlur={quantity.blurHandler}
          type="number"
        />
      </Grid>
      <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="Price"
          placeholder="Price"
          bordered
          value={price.inputValue}
          onChange={price.inputHandler}
          onBlur={price.blurHandler}
          type="number"
        />
      </Grid>
      <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="My Price"
          placeholder="My Price"
          bordered
          value={myPrice.inputValue}
          onChange={myPrice.inputHandler}
          onBlur={myPrice.blurHandler}
          type="number"
        />
      </Grid>
    </>
  );
};

export default Stock;
