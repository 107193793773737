import { Card, Text, Divider } from "@nextui-org/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const MotionCard = motion(Card);

const GroupCard = (props) => {
  return (
    <Link
      to={`/udhar/details/${props.doc?.billno}`}
      state={{ doc: props.doc }}
      className="linkWidth"
    >
      <MotionCard
        variant="bordered"
        css={{
          mw: "550px",
          cursor: "pointer",
          bg: props?.doc?.balance <= 0 ? "$green100" : "$red100",
        }}
        whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
      >
        <Card.Header
          css={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: "5px",
          }}
        >
          <Text b size="14px">
            {props?.doc?.customer?.name}
          </Text>
          <Text
            b
            size="12px"
            css={{ br: "12px", bgColor: "$purple50", px: "10px", py: "5px" }}
            color="$purple800"
          >
            {new Date(props.doc.order_date).toLocaleDateString()}
          </Text>
        </Card.Header>
        <Card.Divider />
        <Card.Body
          css={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            py: "5px",
            alignItems: "center",
          }}
        >
          <div className="groupCardFlex">
            <Text size="13px" b>
              CtPins:
            </Text>
            <Text size="13px" b>
              {props.doc.order?.products1?.map((doc) => doc?.ctpin)?.join(", ")}
            </Text>
          </div>
          <Divider />
          <div className="groupCardFlex">
            <Text size="13px" b>
              Mobile:
            </Text>
            <Text size="13px" b>
              {props.doc.customer?.mobile}
            </Text>
          </div>
          <Divider />

          <div className="groupCardFlex">
            <Text size="13px" b>
              Total
            </Text>
            <Text size="13px" b>
              &#8377; {props.doc.total}
            </Text>
          </div>
          <Divider />
          <div className="groupCardFlex">
            <Text size="13px" b>
              Paid:
            </Text>
            <Text size="13px" b>
              &#8377; {props.doc.total_paid}
            </Text>
          </div>
          <Divider />
          <div className="groupCardFlex">
            <Text size="13px" b>
              Pending:
            </Text>
            <Text size="13px" b>
              &#8377; {props.doc.balance}
            </Text>
          </div>
        </Card.Body>
      </MotionCard>
    </Link>
  );
};

export default GroupCard;
