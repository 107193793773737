import { useEffect, useState } from "react";
import { Table, Card, Text, Button, Input, Badge } from "@nextui-org/react";
import { TbSearch, TbList, TbSortAscending } from "react-icons/tb";

const columns = [
  { key: "date", label: "Date" },
  { key: "time", label: "Time" },
  { key: "name", label: "Input" },
  { key: "amount", label: "Amount" },
  { key: "reason", label: "Type" },
];

const ExpensesTable = (props) => {
  const [list, setList] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    field: "order_date",
    asc: false,
  });
  const [search, setSearch] = useState("");
  const changeCurrentSort = (field, asc) => {
    setCurrentSort({ field: field, asc: asc });
  };

  useEffect(() => {
    if (props.list?.length > 0) {
      let listCopy = [...props.list];
      let sortCurrent;

      const regex = new RegExp(
        search?.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&"),
        "i"
      );

      if (search !== "") {
        listCopy = listCopy?.filter((doc) => {
          return regex.test(doc?.input);
        });
      }

      if (currentSort.field === "name") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.input;
          let fb = b?.input;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "reason") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.type;
          let fb = b?.type;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "amount") {
        sortCurrent = listCopy.sort((a, b) => {
          return a?.amount - b?.amount;
        });
      } else {
        sortCurrent = listCopy.sort((a, b) => {
          return new Date(a.order_date) - new Date(b.order_date);
        });
      }

      setList(currentSort.asc ? sortCurrent : sortCurrent.reverse());
    }
  }, [currentSort, props.list, search]);

  return (
    <Card variant="bordered" className="cardShadow" css={{ maxW: 750 }}>
      <Card.Header css={{ p: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 20px 20px 20px",
            width: "100%",
          }}
        >
          <Text h4 css={{ margin: 0 }}>
            Records
          </Text>
          <Input
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            bordered
          />
        </div>
      </Card.Header>
      <Card.Body css={{ p: 0 }}>
        <Table
          striped
          aria-label="Customers List"
          compact
          sticked
          shadow={false}
        >
          <Table.Header>
            {columns.map((column) => {
              return (
                <Table.Column key={column.key} css={{ color: "black" }}>
                  <div className="tableColumn">
                    {column.label}&nbsp;&nbsp;&nbsp;
                    <Button
                      auto
                      light
                      size="xs"
                      onClick={() =>
                        changeCurrentSort(column.key, !currentSort.asc)
                      }
                    >
                      <TbSortAscending />
                    </Button>
                  </div>
                </Table.Column>
              );
            })}
          </Table.Header>
          <Table.Body
            css={{ textAlign: "start", fontWeight: 600, verticalAlign: "top" }}
          >
            {list?.map((doc) => {
              return (
                <Table.Row
                  key={doc._id}
                  css={{
                    borderRadius: "12px",
                    alignItems: "start",
                    fontSize: "14px",
                  }}
                >
                  <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.createdAt)?.toLocaleDateString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </Table.Cell>
                  <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.createdAt)?.toLocaleTimeString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </Table.Cell>
                  <Table.Cell>{doc?.input}</Table.Cell>
                  <Table.Cell>
                    {doc?.type === "sale" ? (
                      <Badge variant={"flat"} color={"error"}>
                        -&#8377; {doc?.amount}
                      </Badge>
                    ) : (
                      <Badge variant={"flat"} color={"success"}>
                        +&#8377; {doc?.amount}
                      </Badge>
                    )}
                  </Table.Cell>
                  <Table.Cell css={{ textTransform: "capitalize" }}>
                    {doc?.type}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Pagination
            size="sm"
            color="error"
            shadow
            align="center"
            rowsPerPage={10}
            total={Math.ceil(list?.length / 10)}
          />
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ExpensesTable;
