import {
  Card,
  Text,
  Input,
  Button,
  Divider,
  Badge,
  Loading,
  Modal,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import { Link, useLocation, useParams } from "react-router-dom";
import { TbArrowBackUp, TbCheck, TbFileExport } from "react-icons/tb";
import * as Xlsx from "xlsx";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

const MotionButton = motion(Button);

const OrderDetails = () => {
  const { billno } = useParams();

  const [data, setData] = useState(null);
  const [load, setLoad] = useState(false);
  const fetchUdharDetails = async () => {
    try {
      setLoad(true);
      const resp = await fetch("/get-single-bill/" + billno).then((res) =>
        res.json()
      );

      console.log(resp);

      if (resp) {
        setData(resp);
      }

      setLoad(false);
    } catch (err) {
      console.log(err);
      setLoad(false);
    }
  };

  useEffect(() => {
    fetchUdharDetails();
  }, []);

  return (
    <motion.div>
      <motion.div className="header">
        <Link to={-1} className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>{load ? "Loading" : billno}</Text>
        <div className="headerLink headerLinkAddon">
          {data?.balance <= 0 ? <Badge>Paid</Badge> : <Badge>Pending</Badge>}
        </div>
      </motion.div>
      <motion.div
        className="qtyContainer alignDivCenter"
        // initial={{ x: "100%" }}
        // animate={{ x: 0 }}
        // transition={{ type: "spring" }}
      >
        <Card variant="bordered" css={{ mw: "550px" }}>
          <Card.Header
            css={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontWeight: 500,
            }}
          >
            <div className="detailsText">
              <Text size="14px">Name</Text>
              <Text size="14px">{data?.customer?.name}</Text>
            </div>
            <Divider />
            <div className="detailsText">
              <Text size="14px">Mobile</Text>
              <Text size="14px">{data?.customer?.mobile}</Text>
            </div>
            <Divider />
            <div className="detailsText">
              <Text size="14px">Date</Text>
              <Text size="14px">
                {new Date(data?.order_date).toLocaleDateString()}
              </Text>
            </div>
            <Divider />
            <div className="detailsText">
              <Text size="14px">Bill Name</Text>
              <Text size="14px">{data?.billName}</Text>
            </div>
            <Divider />
            <div className="detailsText">
              <Text size="14px">Total Paid</Text>
              <Text size="14px">
                &#8377;&nbsp;
                {data?.total_paid}
              </Text>
            </div>
            <Divider />
            <div className="detailsText">
              <Text b size="14px">
                Total
              </Text>
              <Text b size="14px">
                &#8377;&nbsp;
                {data?.total}
              </Text>
            </div>
          </Card.Header>
          <Card.Divider />
          <Card.Body
            css={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <Text
              css={{
                br: "12px",
                bgColor: "$pink50",
                px: "10px",
                w: "fit-content",
                mt: 10,
              }}
              color="$pink800"
              b
            >
              Products
            </Text>
            <motion.div className="productDiv">
              {data?.products1.map((doc) => {
                return (
                  <Card
                    variant="bordered"
                    css={{
                      py: "5px",
                      px: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      minHeight: "100px",
                    }}
                    key={doc._id}
                  >
                    <div>
                      <Text size="14px" b>
                        {doc.name}
                      </Text>
                      <Text size="12px">Payment: {data?.payment_type}</Text>
                      <Text size="12px">Type: {doc.desc}</Text>
                      <Text size="12px">Ctpin: {doc.ctpin}</Text>
                    </div>
                    <div className="prodBadge">
                      <Badge>{doc.quantity}</Badge>
                      <Text b size="14px">
                        &#8377;{doc.price}
                      </Text>
                    </div>
                  </Card>
                );
              })}
            </motion.div>
          </Card.Body>
        </Card>
      </motion.div>
    </motion.div>
  );
};

export default OrderDetails;
