import { Button, Grid, Loading } from "@nextui-org/react";
import { TbCheck, TbRefresh, TbSortAscending } from "react-icons/tb";
import { useEffect, useState } from "react";
import useSyncHook from "./hooks/use-sync-hook";
import Items from "./items";

const ManageItemOrder = () => {
  const [type, setType] = useState("iPhone");
  const { load, syncHandler, items, orderLoad, updateOrderHandler } =
    useSyncHook();
  const [data, setData] = useState({});
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    syncHandler();
  }, []);

  useEffect(() => {
    setData(items ?? {});
  }, [items]);

  return (
    <>
      <div>
        <div className="header" style={{ padding: "30px 20px" }}>
          <Button
            icon={
              orderLoad ? (
                <Loading />
              ) : drag ? (
                <TbCheck size={"1.5rem"} />
              ) : (
                <TbSortAscending size={"1.5rem"} />
              )
            }
            light
            size="md"
            auto
            rounded
            onClick={async () => {
              if (drag) {
                await updateOrderHandler(data);
                setDrag(false);
              } else {
                setDrag(true);
              }
            }}
          />
          <img src="https://store.marvansmobile.com/logo.png" width={75} />
          <Button
            icon={load ? <Loading /> : <TbRefresh size={"1.5rem"} />}
            light
            rounded
            size="md"
            auto
            onClick={syncHandler}
          />
        </div>
      </div>
      <div className="qtyContainer alignDivCenter">
        <Grid.Container gap={1}>
          <Grid xs={3}>
            <Button
              onClick={() => setType("iPhone")}
              css={{ w: "100%" }}
              size="sm"
              auto
              bordered={type !== "iPhone"}
            >
              iPhone
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              onClick={() => setType("Android")}
              css={{ w: "100%" }}
              size="sm"
              auto
              bordered={type !== "Android"}
            >
              Android
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              onClick={() => setType("Watch")}
              css={{ w: "100%" }}
              size="sm"
              auto
              bordered={type !== "Watch"}
            >
              Watch
            </Button>
          </Grid>
          <Grid xs={3}>
            <Button
              onClick={() => setType("Airpods")}
              css={{ w: "100%" }}
              size="sm"
              auto
              bordered={type !== "Airpods"}
            >
              Airpods
            </Button>
          </Grid>
        </Grid.Container>
        <Items items={data} setItems={setData} drag={drag} type={type} />
      </div>
    </>
  );
};

export default ManageItemOrder;
