import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { TbBrandAdobe, TbMessage } from "react-icons/tb";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Message = ({ data, date }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.invtry.token);

  const handler = async () => {
    try {
      setLoading(true);

      const resp = await fetch(
        `https://stockmanager-prod-cron.vercel.app/send-summary-report?date=${date}`
      ).then((res) => res.json());

      console.log(resp);

      setLoading(false);
      return toast("Success", {
        position: "top-right",
        autoClose: 1000,
        type: "success",
      });
    } catch (err) {
      setLoading(false);
      return toast("An error occured!", {
        position: "top-right",
        autoClose: 1000,
        type: "error",
      });
    }
  };

  return (
    <>
      <div>
        <Button
          icon={loading ? <Loading type="spinner" /> : <TbMessage />}
          onClick={handler}
          disabled={loading}
          color={"error"}
          auto
          size={"sm"}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default Message;
