import { useEffect, useState } from "react";
import { Table, Card, Text, Button, Input, Badge } from "@nextui-org/react";
import { TbSearch, TbList, TbSortAscending } from "react-icons/tb";

const columns = [
  // { key: "date", label: "Date" },
  { key: "time", label: "Time" },
  { key: "product", label: "Product" },
  { key: "ctpin", label: "CtPin" },
  { key: "cash", label: "Cash" },
  { key: "card", label: "Card" },
  { key: "cashfree", label: "Cashfree" },
  { key: "online", label: "Online" },
  { key: "udhar", label: "Udhar" },
  { key: "name", label: "Name" },
];

const HistoryTable = (props) => {
  const [list, setList] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    field: "order_date",
    asc: false,
  });
  const changeCurrentSort = (field, asc) => {
    setCurrentSort({ field: field, asc: asc });
  };

  useEffect(() => {
    if (props.list?.length > 0) {
      const listCopy = [...props.list];
      let sortCurrent;

      if (currentSort.field === "product") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.products1[0]?.name;
          let fb = b?.products1[0]?.name;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "name") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.billName;
          let fb = b?.billName;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "cash") {
        sortCurrent = listCopy.sort((a, b) => {
          return (
            (a?.payment_type === "Other"
              ? a?.paid_struc?.cash
              : a?.payment_type === "Cash"
              ? a?.total
              : 0) -
            (b?.payment_type === "Other"
              ? b?.paid_struc?.cash
              : b?.payment_type === "Cash"
              ? b?.total
              : 0)
          );
        });
      } else if (currentSort.field === "card") {
        sortCurrent = listCopy.sort((a, b) => {
          return (
            (a?.payment_type === "Other"
              ? a?.paid_struc?.card
              : a?.payment_type === "Card"
              ? a?.total
              : 0) -
            (b?.payment_type === "Other"
              ? b?.paid_struc?.card
              : b?.payment_type === "Card"
              ? b?.total
              : 0)
          );
        });
      } else if (currentSort.field === "online") {
        sortCurrent = listCopy.sort((a, b) => {
          return (
            (a?.payment_type === "Other"
              ? a?.paid_struc?.bank
              : a?.payment_type === "Online"
              ? a?.total
              : 0) -
            (b?.payment_type === "Other"
              ? b?.paid_struc?.bank
              : b?.payment_type === "Online"
              ? b?.total
              : 0)
          );
        });
      } else if (currentSort.field === "cashfree") {
        sortCurrent = listCopy.sort((a, b) => {
          return (
            (a?.payment_type === "Cashfree" ? a?.total : 0) -
            (b?.payment_type === "Cashfree" ? b?.total : 0)
          );
        });
      } else if (currentSort.field === "udhar") {
        sortCurrent = listCopy.sort((a, b) => {
          return (a?.paid_struc?.loaned ?? 0) - (b?.paid_struc?.loaned ?? 0);
        });
      } else {
        sortCurrent = listCopy.sort((a, b) => {
          return new Date(a.order_date) - new Date(b.order_date);
        });
      }

      setList(currentSort.asc ? sortCurrent : sortCurrent.reverse());
    } else {
      setList([]);
    }
  }, [currentSort, props.list]);

  return (
    <Card variant="bordered" className="cardShadow" css={{ maxW: "1200px" }}>
      <Card.Header css={{ p: 0 }}>
        <Text h4 css={{ margin: 0, padding: "20px 20px 0px 20px" }}>
          Products
        </Text>
      </Card.Header>
      <Card.Body css={{ p: 0 }}>
        <Table
          striped
          aria-label="Customers List"
          compact
          sticked
          shadow={false}
        >
          <Table.Header>
            {columns.map((column) => {
              return (
                <Table.Column key={column.key} css={{ color: "black" }}>
                  <div className="tableColumn">
                    {column.label}&nbsp;&nbsp;&nbsp;
                    <Button
                      auto
                      light
                      size="xs"
                      onClick={() =>
                        changeCurrentSort(column.key, !currentSort.asc)
                      }
                    >
                      <TbSortAscending />
                    </Button>
                  </div>
                </Table.Column>
              );
            })}
          </Table.Header>
          <Table.Body
            css={{ textAlign: "start", fontWeight: 600, verticalAlign: "top" }}
          >
            {list?.map((doc) => {
              return (
                <Table.Row
                  key={doc._id}
                  css={{
                    borderRadius: "12px",
                    alignItems: "start",
                    fontSize: "14px",
                  }}
                >
                  {/* <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.order_date)?.toLocaleDateString()}
                  </Table.Cell> */}
                  <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.order_date)?.toLocaleTimeString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {doc?.products1?.map((doc) => {
                        return (
                          <>
                            <Text b>
                              {doc?.name} {doc?.desc ? `(${doc.desc})` : ""}
                            </Text>
                          </>
                        );
                      })}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {doc?.products1?.map((doc) => {
                        return (
                          <>
                            <Text b>{doc?.ctpin}</Text>
                          </>
                        );
                      })}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    &#8377;{" "}
                    {doc?.payment_type === "Other"
                      ? doc?.paid_struc?.cash
                      : doc?.payment_type === "Cash"
                      ? doc?.total
                      : 0}
                  </Table.Cell>
                  <Table.Cell>
                    &#8377;{" "}
                    {doc?.payment_type === "Other"
                      ? doc?.paid_struc?.card
                      : doc?.payment_type === "Card"
                      ? doc?.total
                      : 0}
                  </Table.Cell>
                  <Table.Cell>
                    &#8377; {doc?.payment_type === "Cashfree" ? doc?.total : 0}
                  </Table.Cell>
                  <Table.Cell>
                    &#8377;{" "}
                    {doc?.payment_type === "Other"
                      ? doc?.paid_struc?.bank
                      : doc?.payment_type === "Online"
                      ? doc?.total
                      : 0}
                  </Table.Cell>
                  <Table.Cell>
                    &#8377; {doc?.paid_struc?.loaned ?? 0}
                  </Table.Cell>
                  <Table.Cell> {doc?.billName}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default HistoryTable;
