import { Button, Grid, Input, Loading, Spinner, Text } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { TbArrowBackUp, TbRefresh } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HistoryTable from "./list";

const Product = () => {
  const token = useSelector((state) => state.invtry.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const loadHandler = () => {
    setLoading(true);
    setError(false);
    fetch("/get-products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((d) => {
        console.log(d);
        setData(d.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  useEffect(() => {
    loadHandler();
  }, []);

  return (
    <>
      <motion.div className="header">
        <Link to="/" className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>All Products</Text>
        <Button
          auto
          size={"sm"}
          disabled={loading}
          onClick={loadHandler}
          icon={
            loading ? <Loading type="spinner" color={"error"} /> : <TbRefresh />
          }
        />
      </motion.div>
      <motion.div className="qtyContainer alignDivCenter">
        <HistoryTable list={data} load={loadHandler} />
      </motion.div>
    </>
  );
};

export default Product;
