import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { invtryActions } from "../../../redux/inventorySlice";

const useSyncHook = () => {
  const [load, setLoad] = useState(false);
  const [orderLoad, setOrderLoad] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const items = useSelector((state) => state.invtry.manageItems);

  const getItems = async () => {
    try {
      const resp = await fetch("/get-manage-items").then((res) => res.json());
      dispatch(invtryActions.setManageItems(resp.items));
    } catch (err) {
      console.log(err);
    }
  };

  const editPriceHandler = async (id, price) => {
    try {
      setLoad(true);
      let resp = await fetch("/update-price", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, price }),
      }).then((res) => res.json());

      if (!resp?.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await getItems();
      setLoad(false);
      toast("Saved", {
        type: "success",
        autoClose: 1500,
      });
    } catch (err) {
      console.log(err);
      toast(err?.message ?? "Something went wrong!", {
        type: "error",
        autoClose: 1500,
      });
      setLoad(false);
    }
  };

  const cloneHandler = async (id, name) => {
    try {
      setLoad(true);
      let resp = await fetch("/create-clone", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id, name }),
      }).then((res) => res.json());

      if (!resp?.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await getItems();
      setLoad(false);
      toast("Saved", {
        type: "success",
        autoClose: 1500,
      });
    } catch (err) {
      console.log(err);
      toast(err?.message ?? "Something went wrong!", {
        type: "error",
        autoClose: 1500,
      });
      setLoad(false);
    }
  };

  const deleteHandler = async (id) => {
    try {
      setLoad(true);
      let resp = await fetch(`/delete-manage-item/${id}`).then((res) =>
        res.json()
      );

      if (!resp?.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await getItems();
      setLoad(false);
      toast("Saved", {
        type: "success",
        autoClose: 1500,
      });
    } catch (err) {
      console.log(err);
      toast(err?.message ?? "Something went wrong!", {
        type: "error",
        autoClose: 1500,
      });
      setLoad(false);
    }
  };

  const syncHandler = async () => {
    try {
      setLoad(true);
      let resp = await fetch("/sync-items").then((res) => res.json());

      if (!resp?.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await getItems();
      setLoad(false);
      toast("Synced", {
        type: "success",
        autoClose: 1500,
      });
    } catch (err) {
      console.log(err);
      toast(err?.message ?? "Something went wrong!", {
        type: "error",
        autoClose: 1500,
      });
      setLoad(false);
    }
  };

  const updateOrderHandler = async (items) => {
    try {
      setOrderLoad(true);
      let arr = [];

      items.iphones?.forEach((doc, i) => {
        arr.push({ _id: doc._id, order: i });
      });

      items.androids?.forEach((doc, i) => {
        arr.push({ _id: doc._id, order: i });
      });

      items.iwatches?.forEach((doc, i) => {
        arr.push({ _id: doc._id, order: i });
      });

      items.ipods?.forEach((doc, i) => {
        arr.push({ _id: doc._id, order: i });
      });

      let resp = await fetch("/update-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ arr }),
      }).then((res) => res.json());

      if (!resp?.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await getItems();
      setOrderLoad(false);
      toast("Saved", {
        type: "success",
        autoClose: 1500,
      });
    } catch (err) {
      console.log(err);
      toast(err?.message ?? "Something went wrong!", {
        type: "error",
        autoClose: 1500,
      });
      setOrderLoad(false);
    }
  };

  return {
    syncHandler,
    load,
    items,
    orderLoad,
    editPriceHandler,
    updateOrderHandler,
    cloneHandler,
    deleteHandler,
  };
};

export default useSyncHook;
