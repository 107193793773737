import { Loading, Spinner, Text } from "@nextui-org/react";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { TbArrowBackUp } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CreateRecord from "./create";
import Stats from "./stats";
import ExpensesTable from "./list";
const d = new Date();

const Calculator = () => {
  const token = useSelector((state) => state.invtry.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(
    new Date(`${d?.getFullYear()}-01-01`).toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date(`${d?.getFullYear()}-12-31`).toISOString().substring(0, 10)
  );
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);

  const loadHandler = async () => {
    setLoading(true);
    setError(false);
    await fetch("/get-all-stocks", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setError(true);
          return setLoading(false);
        }

        setData(data?.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  const loadByHandler = async () => {
    setLoading(true);
    setError(false);
    await fetch("/get-stocks", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ lte: endDate, gte: date }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          setError(true);
          return setLoading(false);
        }

        console.log(data?.items);
        setCount(data?.items);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        return setLoading(false);
      });
  };

  const loadAll = async () => {
    await loadByHandler();
    await loadHandler();
  };

  useEffect(() => {
    loadAll();
  }, []);

  useEffect(() => {
    loadByHandler();
  }, [date, endDate]);

  return (
    <>
      <motion.div className="header">
        <Link to="/" className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>Calculator</Text>

        <div className="headerLink">
          {loading && <Loading type="spinner" color={"error"} />}
        </div>
      </motion.div>
      <motion.div className="qtyContainer alignDivCenter">
        <Stats
          date={date}
          setDate={setDate}
          endDate={endDate}
          setEndDate={setEndDate}
          count={count}
        />
        <CreateRecord refresh={loadAll} />
        <ExpensesTable list={data} />
      </motion.div>
    </>
  );
};

export default Calculator;
