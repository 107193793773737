import { Badge, Button, Input, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { TbArrowUpCircle, TbEditCircle, TbGridDots } from "react-icons/tb";
import useSyncHook from "./hooks/use-sync-hook";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SortableList from "./sortable-list";

const Items = ({ items, setItems, drag, type }) => {
  return (
    <>
      {type === "iPhone" && (
        <div
          style={{
            width: "100%",
            paddingTop: 10,
            textAlign: "left",
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <SortableList
              items={items?.iphones ?? []}
              setItems={(v) => {
                console.log(v);
                setItems({ ...items, iphones: v });
              }}
              drag={drag}
            />
          </div>
        </div>
      )}
      {type === "Android" && (
        <div
          style={{
            width: "100%",
            paddingTop: 10,
            textAlign: "left",
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <SortableList
              items={items?.androids ?? []}
              setItems={(v) => {
                console.log(v);
                setItems({ ...items, androids: v });
              }}
              drag={drag}
            />
          </div>
        </div>
      )}
      {type === "Watch" && (
        <div
          style={{
            width: "100%",
            paddingTop: 10,
            textAlign: "left",
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <SortableList
              items={items?.iwatches ?? []}
              setItems={(v) => {
                console.log(v);
                setItems({ ...items, iwatches: v });
              }}
              drag={drag}
            />
          </div>
        </div>
      )}
      {type === "Airpods" && (
        <div
          style={{
            width: "100%",
            paddingTop: 10,
            textAlign: "left",
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 5,
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <SortableList
              items={items?.airpods ?? []}
              setItems={(v) => {
                console.log(v);
                setItems({ ...items, airpods: v });
              }}
              drag={drag}
            />
          </div>
        </div>
      )}
    </>
  );
};

export const Item = ({ doc, id, drag }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, data: doc });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [edit, setEdit] = useState(false);
  const [price, setPrice] = useState(0);
  const [name, setName] = useState("");

  const { editPriceHandler, load, cloneHandler, deleteHandler } = useSyncHook();

  useEffect(() => {
    setPrice(doc?.price ?? 0);
  }, [doc]);

  return (
    <>
      <div
        style={{
          width: "100%",
          padding: 10,
          border: "1px solid #f2e580",
          borderRadius: 12,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", gap: 5 }}>
            {drag && (
              <div
                ref={setNodeRef}
                {...attributes}
                {...listeners}
                style={{
                  height: "100%",
                  width: 30,
                  height: 30,
                  backgroundColor: "#f8f8f8",
                  borderRadius: 12,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TbGridDots />
              </div>
            )}
            <div
              style={{
                ...style,
                display: "flex",
                flexDirection: "column",
                gap: 5,
              }}
            >
              <Text style={{ fontSize: 14, fontWeight: 500, lineHeight: 1 }}>
                {doc?.isClone ? `${doc.name} ${doc?.cloneSuffix}` : doc?.name}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: 1,
                  opacity: 0.7,
                }}
              >
                &#8377; {(doc.price ?? 0).toFixed(2)}
              </Text>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: 5,
              alignItems: "center",
            }}
          >
            <Badge>{doc?.variant}</Badge>
            <div
              style={{
                background: "#1c66cd",
                borderRadius: 12,
                width: 30,
                height: 30,
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => (edit ? setEdit(false) : setEdit(true))}
            >
              {edit ? <TbArrowUpCircle /> : <TbEditCircle />}
            </div>
          </div>
        </div>
        {edit && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 10,
                gap: 5,
              }}
            >
              <Input
                width="100%"
                placeholder="Price"
                size="sm"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <Button
                size="sm"
                onClick={async () => {
                  await editPriceHandler(doc._id, price);
                  setEdit(false);
                }}
                disabled={load || price < 0}
              >
                {load ? "Wait..." : "Save"}
              </Button>
            </div>
            {!doc.isClone ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 10,
                  gap: 5,
                }}
              >
                <Input
                  width="100%"
                  placeholder="Suffix"
                  size="sm"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <Button
                  size="sm"
                  onClick={async () => {
                    await cloneHandler(doc._id, name);
                    setEdit(false);
                  }}
                  disabled={load || name === ""}
                >
                  {load ? "Wait..." : "Clone"}
                </Button>
              </div>
            ) : (
              <Button
                size="sm"
                onClick={async () => {
                  if (window.confirm("Are you sure?")) {
                    await deleteHandler(doc._id);
                    setEdit(false);
                  }
                }}
                disabled={load}
                color="error"
                css={{ mt: 10, w: "100%" }}
              >
                {load ? "Wait..." : "Delete"}
              </Button>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Items;
