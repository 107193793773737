import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { TbBrandAdobe } from "react-icons/tb";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Print = ({ data, date }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.invtry.token);

  const handler = async () => {
    setLoading(true);

    fetch("/print-pdf", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data, date }),
    })
      .then((res) => {
        if (res.status != 200) {
          setLoading(false);
          return toast("An error occured!", {
            position: "top-right",
            autoClose: 1000,
            type: "error",
          });
        }

        return res.blob();
      })
      .then((blob) => {
        if (blob.size <= 0) {
          setLoading(false);
          return toast("An error occurred!", {
            position: "top-right",
            autoClose: 1000,
            type: "error",
          });
        }

        const url = window.URL.createObjectURL(
          new Blob([blob], { type: "application/pdf" })
        );

        // Create an anchor element
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = `${date}.pdf`; // You can set a default name or get it from the response headers

        // Trigger a click on the anchor element
        document.body.appendChild(a); // Safari requires the element to be in the DOM
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);

        setLoading(false);
        return toast("Success", {
          position: "top-right",
          autoClose: 1000,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return toast("An error occured!", {
          position: "top-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };

  return (
    <>
      <div>
        <Button
          icon={loading ? <Loading type="spinner" /> : <TbBrandAdobe />}
          onClick={handler}
          disabled={loading}
          color={"error"}
          auto
          size={"sm"}
        />
        <ToastContainer />
      </div>
    </>
  );
};

export default Print;
