import { useState } from "react";

function useInput(validation) {
  const [inputValue, setInputValue] = useState(0);
  const [isBlur, setIsBlur] = useState();

  const isValid = validation(inputValue);
  const error = !isValid && isBlur;

  function inputHandler(event) {
    setInputValue(event.currentTarget.value);
  }

  function blurHandler(event) {
    setIsBlur(true);
  }

  function clearInput() {
    setInputValue(0);
    setIsBlur(false);
  }

  return {
    inputValue,
    error,
    isValid,
    inputHandler,
    blurHandler,
    clearInput,
  };
}

export function useInput2(validation, value) {
  const [inputValue, setInputValue] = useState(value ?? "");
  const [isBlur, setIsBlur] = useState();

  const isValid = validation(inputValue);
  const error = !isValid && isBlur;

  function inputHandler(event) {
    setInputValue(event.currentTarget.value);
  }

  function blurHandler(event) {
    setIsBlur(true);
  }

  function clearInput() {
    setInputValue("");
    setIsBlur(false);
  }

  function setInput(v) {
    setInputValue(v);
  }

  return {
    input: {
      inputValue,
      error,
      isValid,
      inputHandler,
      blurHandler,
      clearInput,
      setInput,
    },
  };
}

export default useInput;
