import { useEffect, useState } from "react";
import { Table, Card, Text, Button, Input, Badge } from "@nextui-org/react";
import {
  TbSearch,
  TbList,
  TbSortAscending,
  TbEdit,
  TbTrack,
  TbTrash,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const columns = [
  { key: "Name", label: "Name" },
  { key: "Type", label: "Type" },
  // { key: "Desc", label: "Desc" },
  { key: "Action", label: "Action" },
];

const HistoryTable = (props) => {
  const [row, setRow] = useState(20);
  const token = useSelector((state) => state.invtry.token);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentSort, setCurrentSort] = useState({
    field: "name",
    asc: false,
  });
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const changeCurrentSort = (field, asc) => {
    setCurrentSort({ field: field, asc: asc });
  };

  const deleteHandler = async (id, type) => {
    await fetch("/delete-product", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, type }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data.success) {
          return setLoading(false);
        }

        window.alert("SUCCESS");
        props.load();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        window.alert("An error occured, please try again.");
        return setLoading(false);
      });
  };

  useEffect(() => {
    console.log(props.list);
    if (props.list?.length > 0) {
      let listCopy = [...props.list];
      let sortCurrent;

      const regex = new RegExp(search, "i");

      if (search !== "") {
        listCopy = listCopy?.filter((doc) => {
          return (
            regex.test(doc?.name) ||
            regex.test(doc?.type) ||
            regex.test(doc.desc)
          );
        });
      }

      if (currentSort.field === "name") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.name;
          let fb = b?.name;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "type") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.type;
          let fb = b?.type;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else {
        sortCurrent = listCopy.sort((a, b) => {
          return (sortCurrent = listCopy.sort((a, b) => {
            let fa = a?.desc;
            let fb = b?.desc;
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }));
        });
      }

      setList(currentSort.asc ? sortCurrent : sortCurrent.reverse());
    } else {
      setList([]);
    }
  }, [currentSort, props.list, search]);

  return (
    <Card variant="bordered" className="cardShadow" css={{ maxW: "1200px" }}>
      <Card.Header
        css={{
          p: "10px 15px",
          mt: 10,
          display: "flex",
          justifyContent: "right",
        }}
      >
        <Input
          bordered
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search..."
        />
      </Card.Header>
      <Card.Body css={{ p: 0 }}>
        <Table
          striped
          aria-label="Customers List"
          compact
          sticked
          shadow={false}
        >
          <Table.Header>
            {columns.map((column) => {
              return (
                <Table.Column key={column.key} css={{ color: "black" }}>
                  <div className="tableColumn">
                    {column.label}&nbsp;&nbsp;&nbsp;
                    <Button
                      auto
                      light
                      size="xs"
                      onClick={() =>
                        changeCurrentSort(column.key, !currentSort.asc)
                      }
                    >
                      <TbSortAscending />
                    </Button>
                  </div>
                </Table.Column>
              );
            })}
          </Table.Header>
          <Table.Body
            css={{ textAlign: "start", fontWeight: 600, verticalAlign: "top" }}
          >
            {list?.map((doc) => {
              return (
                <Table.Row
                  key={doc._id}
                  css={{
                    borderRadius: "12px",
                    alignItems: "start",
                    fontSize: "14px",
                  }}
                >
                  <Table.Cell>{doc?.name}</Table.Cell>
                  <Table.Cell>{doc?.type}</Table.Cell>
                  {/* <Table.Cell>{doc?.short_desc}</Table.Cell> */}
                  <Table.Cell css={{ display: "flex", gap: 5 }}>
                    <Button
                      auto
                      // size={"xs"}
                      onClick={() =>
                        navigate(`/edit/${doc._id}?type=${doc.type}`)
                      }
                      icon={<TbEdit />}
                      css={{ p: 0 }}
                    />
                    <Button
                      color={"error"}
                      disabled={loading}
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this product"
                          )
                        ) {
                          deleteHandler(doc._id, doc.type);
                        }
                      }}
                      auto
                      // size={"xs"}
                      icon={<TbTrash />}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default HistoryTable;
