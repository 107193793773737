import { Card, Text, Input, Button, Loading } from "@nextui-org/react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { TbArrowBackUp, TbDownload } from "react-icons/tb";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { invtryActions } from "../redux/inventorySlice";
import GroupCard from "../components/ui/UdharCard";

const MotionButton = motion(Button);
const btnAnim = { whileTap: { scale: 0.9 } };

const Udhar = () => {
  const token = useSelector((state) => state.invtry.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [viewBy, setViewBy] = useState("non-paid");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const storedReports = useSelector((state) => state.invtry.storedUdhars);
  const dateGte = useSelector((state) => state.invtry.dateGte);
  const dateLte = useSelector((state) => state.invtry.dateLte);

  useEffect(() => {
    setLoading(true);
    setError(false);
    fetchReport();
  }, [dateGte, dateLte]);

  const fetchReport = () => {
    if (!dateGte || !dateLte) {
      return;
    }

    setLoading(true);
    setError(false);
    fetch(`/get-udhars?gte=${dateGte}&lte=${dateLte}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          setError(true);
        }

        return res.json();
      })
      .then((data) => {
        console.log(data);
        dispatch(invtryActions.setStoredUdhars(data));
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const items = useMemo(() => {
    if (search !== "") {
      let regex = new RegExp(search, "i");
      return storedReports
        .filter(
          (doc) =>
            regex.test(doc?.customer?.name) ||
            regex.test(doc?.customer?.mobile) ||
            regex.test(doc?.billno) ||
            doc?.order?.products1?.some((dc) => regex.test(dc?.ctpin))
        )
        ?.filter((doc) =>
          viewBy === "paid" ? doc?.balance <= 0 : doc?.balance > 0
        );
    } else {
      return storedReports?.filter((doc) =>
        viewBy === "paid" ? doc?.balance <= 0 : doc?.balance > 0
      );
    }
  }, [storedReports, search, viewBy]);

  return (
    <motion.div>
      <motion.div className="header">
        <Link to="/" className="headerLink">
          <TbArrowBackUp className="icoAdjust" />
        </Link>
        <Text b>{loading ? "Loading..." : "Udhar Report"}</Text>
        <div className="headerLink"></div>
      </motion.div>
      <motion.div className="qtyContainer alignDivCenter">
        <Card variant="bordered" css={{ mw: "550px" }}>
          <Card.Body className="reportDate " css={{ py: "10px" }}>
            <Input
              label="Start Date"
              aria-label="Start Date"
              type="date"
              size="xs"
              css={{ w: "auto" }}
              bordered
              value={dateGte}
              onChange={(event) =>
                dispatch(invtryActions.setGte(event.target.value))
              }
            />{" "}
            <Input
              label="End Date"
              aria-label="End Date"
              type="date"
              size="xs"
              bordered
              css={{ w: "auto" }}
              value={dateLte}
              onChange={(event) =>
                dispatch(invtryActions.setLte(event.target.value))
              }
            />
          </Card.Body>
          <Card.Divider />
          <Card.Footer
            css={{ display: "inline-flex", justifyContent: "end", gap: "10px" }}
          >
            <Input
              fullWidth
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {viewBy === "paid" ? (
              <Button
                auto
                color="success"
                onClick={() => setViewBy("non-paid")}
              >
                Paid
              </Button>
            ) : (
              <Button auto color="error" onClick={() => setViewBy("paid")}>
                Non-Paid
              </Button>
            )}
          </Card.Footer>
        </Card>
      </motion.div>
      <motion.div className="ordersDiv alignDivCenter">
        {!loading && error && (
          <Text
            color="error"
            css={{
              br: "12px",
              bgColor: "$red50",
              py: "5px",
              px: "10px",
              my: "10px",
            }}
          >
            An error occured while trying to retrieve data from the server!
          </Text>
        )}
        {!loading &&
          items.length > 0 &&
          items.map((doc) => {
            return <GroupCard key={doc._id} doc={doc} />;
          })}
      </motion.div>
    </motion.div>
  );
};

export default Udhar;
