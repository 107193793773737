import { Button, Loading } from "@nextui-org/react";
import { useState } from "react";
import { TbTrash } from "react-icons/tb";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const DeleteButton = ({ id, refetch }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.invtry.token);

  const deleteHandler = async () => {
    try {
      setLoading(true);
      const resp = await fetch(`/delete-expense-record/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => res.json());

      if (!resp.success) {
        throw new Error(resp?.msg ?? "Something went wrong!");
      }

      await refetch();
      setLoading(false);
      return toast("Deleted!", {
        position: "top-right",
        autoClose: 1000,
        type: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      return toast("An error occured!", {
        position: "top-right",
        autoClose: 1000,
        type: "error",
      });
    }
  };

  return (
    <>
      <Button
        icon={loading ? <Loading type="spinner" /> : <TbTrash />}
        auto
        size={"sm"}
        disabled={loading}
        color="error"
        onClick={() => {
          if (window.confirm("Are you sure?")) {
            deleteHandler();
          }
        }}
      />
    </>
  );
};

export default DeleteButton;
