import { useEffect, useState } from "react";
import { Table, Card, Text, Button, Input, Badge } from "@nextui-org/react";
import { TbSearch, TbList, TbSortAscending } from "react-icons/tb";
import DeleteButton from "./delete-button";

const columns = [
  // { key: "date", label: "Date" },
  { key: "time", label: "Time" },
  { key: "name", label: "Name" },
  { key: "from", label: "From" },
  { key: "reason", label: "Reason" },
  { key: "amount", label: "Amount" },
];

const ExpensesTable = (props) => {
  const [list, setList] = useState([]);
  const [currentSort, setCurrentSort] = useState({
    field: "order_date",
    asc: false,
  });
  const changeCurrentSort = (field, asc) => {
    setCurrentSort({ field: field, asc: asc });
  };

  useEffect(() => {
    if (props.list?.length > 0) {
      const listCopy = [...props.list];
      let sortCurrent;

      if (currentSort.field === "name") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.name;
          let fb = b?.name;
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "from") {
        sortCurrent = listCopy.sort((a, b) => {
          let fa = a?.spendOn ?? "store";
          let fb = b?.spendOn ?? "store";
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else if (currentSort.field === "reason") {
        sortCurrent = listCopy.sort((a, b) => {
          return a?.reason - b?.reason;
        });
      } else if (currentSort.field === "amount") {
        sortCurrent = listCopy.sort((a, b) => {
          return a?.amount - b?.amount;
        });
      } else {
        sortCurrent = listCopy.sort((a, b) => {
          return new Date(a.order_date) - new Date(b.order_date);
        });
      }

      setList(currentSort.asc ? sortCurrent : sortCurrent.reverse());
    } else {
      setList([]);
    }
  }, [currentSort, props.list]);

  return (
    <Card variant="bordered" className="cardShadow" css={{ maxW: "1200px" }}>
      <Card.Header css={{ p: 0 }}>
        <Text h4 css={{ margin: 0, padding: "20px 20px 0px 20px" }}>
          Expenses
        </Text>
      </Card.Header>
      <Card.Body css={{ p: 0 }}>
        <Table
          striped
          aria-label="Customers List"
          compact
          sticked
          shadow={false}
        >
          <Table.Header>
            {columns.map((column) => {
              return (
                <Table.Column key={column.key} css={{ color: "black" }}>
                  <div className="tableColumn">
                    {column.label}&nbsp;&nbsp;&nbsp;
                    <Button
                      auto
                      light
                      size="xs"
                      onClick={() =>
                        changeCurrentSort(column.key, !currentSort.asc)
                      }
                    >
                      <TbSortAscending />
                    </Button>
                  </div>
                </Table.Column>
              );
            })}
            <Table.Column key={"items"} css={{ color: "black" }}></Table.Column>
          </Table.Header>
          <Table.Body
            css={{ textAlign: "start", fontWeight: 600, verticalAlign: "top" }}
          >
            {list?.map((doc) => {
              return (
                <Table.Row
                  key={doc._id}
                  css={{
                    borderRadius: "12px",
                    alignItems: "start",
                    fontSize: "14px",
                  }}
                >
                  {/* <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.createdAt)?.toLocaleDateString()}
                  </Table.Cell> */}
                  <Table.Cell css={{ width: "120px" }}>
                    {new Date(doc?.createdAt)?.toLocaleTimeString("en-IN", {
                      timeZone: "Asia/Kolkata",
                    })}
                  </Table.Cell>
                  <Table.Cell>{doc?.name}</Table.Cell>
                  <Table.Cell>
                    {doc?.spendOn === "personal"
                      ? "From Personal"
                      : "From Store"}
                  </Table.Cell>
                  <Table.Cell>{doc?.reason}</Table.Cell>
                  <Table.Cell>&#8377; {doc?.amount}</Table.Cell>
                  <Table.Cell>
                    <DeleteButton id={doc._id} refetch={props.refetch} />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ExpensesTable;
