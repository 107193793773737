import { Card, Grid, Input, Text } from "@nextui-org/react";
import { useMemo } from "react";

const Stats = ({ date, setDate, endDate, setEndDate, count }) => {
  let stock = useMemo(() => {
    return count?.reduce(
      (a, b) => a + (b?.type === "purchase" ? b?.amount : 0),
      0
    );
  }, [count]);
  let sale = useMemo(() => {
    return count?.reduce((a, b) => a + (b?.type === "sale" ? b?.amount : 0), 0);
  }, [count]);

  return (
    <>
      <Card css={{ p: 5, maxW: 550 }} variant="bordered">
        <Grid.Container gap={1}>
          <Grid xs={6}>
            <Input
              bordered
              placeholder="Start Date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              fullWidth
              type="date"
            />
          </Grid>
          <Grid xs={6}>
            <Input
              bordered
              placeholder="End Date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              type="date"
            />
          </Grid>
        </Grid.Container>
      </Card>
      <Grid.Container gap={1} css={{ maxW: 775 }}>
        <Grid xs={4}>
          <Card css={{ p: 5, bg: "$green50" }} variant="bordered">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <Text b color="$green700">
                Stock
              </Text>
              <Text h4 color="$green700">
                {stock.toLocaleString("en-IN")}
              </Text>
            </div>
          </Card>
        </Grid>
        <Grid xs={4}>
          <Card css={{ p: 5, bg: "$red50" }} variant="bordered">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <Text b color="$red700">
                Sale
              </Text>
              <Text h4 color="$red700">
                {sale?.toLocaleString("en-IN")}
              </Text>
            </div>
          </Card>
        </Grid>
        <Grid xs={4}>
          <Card css={{ p: 5, bg: "$yellow50" }} variant="bordered">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "5px",
                marginTop: "5px",
              }}
            >
              <Text b color="$yellow700">
                Pending
              </Text>
              <Text h4 color="$yellow700">
                {(stock - sale).toLocaleString("en-IN")}
              </Text>
            </div>
          </Card>
        </Grid>
      </Grid.Container>
    </>
  );
};

export default Stats;
