import { Button, Card, Input, Loading, Text } from "@nextui-org/react";
import { useState } from "react";
import { TbBrandAdobe } from "react-icons/tb";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TopSection = ({ date, setDate }) => {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.invtry.token);

  const handler = () => {
    setLoading(true);
    fetch("/clear", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status != 200) {
          setLoading(false);
          return toast("An error occured!", {
            position: "top-right",
            autoClose: 1000,
            type: "error",
          });
        }

        return res.json();
      })
      .then((blob) => {
        if (!blob?.success) {
          // add this condition
          setLoading(false);
          return toast("An error occured!", {
            position: "top-right",
            autoClose: 1000,
            type: "error",
          });
        }

        setLoading(false);
        return toast("Success", {
          position: "top-right",
          autoClose: 1000,
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return toast("An error occured!", {
          position: "top-right",
          autoClose: 1000,
          type: "error",
        });
      });
  };

  return (
    <>
      <Card variant="bordered" style={{ maxWidth: "500px" }}>
        <Card.Body css={{ padding: 10 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Button
              auto
              color={"error"}
              icon={loading ? <Loading type="spinner" /> : <TbBrandAdobe />}
              onClick={handler}
              disabled={loading}
            >
              Clear
            </Button>
            <Input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              type="date"
              css={{ w: "100%" }}
            />
          </div>
        </Card.Body>
      </Card>
      <ToastContainer />
    </>
  );
};

export default TopSection;
