import { Button, Grid, Input, Text } from "@nextui-org/react";
import { useInput2 } from "../../hooks/useInput";
import { FiX } from "react-icons/fi";

const Variants = ({ variants, setVariants, type }) => {
  const { input: storage } = useInput2((v) => v !== "", "");
  const { input: color } = useInput2((v) => v !== "", ".");
  const { input: size } = useInput2((v) => v !== "", "");
  const { input: quantity } = useInput2((v) => v > 0, "");
  const { input: price } = useInput2((v) => v > 0, "");
  const { input: myPrice } = useInput2((v) => v > 0, "");

  const addHandler = () => {
    if (type === "iWatch") {
      setVariants([
        ...variants,
        {
          type: storage.inputValue,
          color: color.inputValue,
          price: price.inputValue,
          my_price: myPrice.inputValue,
          size: size.inputValue,
          quantity: quantity.inputValue,
          isInStock: true,
        },
      ]);
    } else {
      setVariants([
        ...variants,
        {
          storage: storage.inputValue,
          color: color.inputValue,
          price: price.inputValue,
          my_price: myPrice.inputValue,
          quantity: quantity.inputValue,
          isInStock: true,
        },
      ]);
    }

    storage.setInput("");
    color.setInput("");
    price.setInput("");
    myPrice.setInput("");
    quantity.setInput("");
    size.setInput("");
  };

  const removeHandler = (i) => {
    const copy = [...variants]?.filter((doc, idx) => {
      return idx !== i;
    });
    setVariants(copy);
  };

  return (
    <>
      <Grid xs={12} md={4}>
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Text style={{ textAlign: "start" }}>Storage</Text>
          <div className="selectCustom">
            <select
              id="select"
              value={storage.inputValue}
              onChange={(e) => storage.setInput(e.target.value)}
              onBlur={storage.blurHandler}
              aria-label="Select Category"
              defaultValue={""}
              style={{ border: "none", width: "100%" }}
            >
              {(type === "iPhone"
                ? ["", "32 GB", "64 GB", "128 GB", "256 GB", "512 GB", "1 TB"]
                : ["", "GPS", "GPS + CELLULAR"]
              )?.map((doc, i) => {
                return (
                  <option value={doc} key={i}>
                    {doc}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </Grid>
      {/* <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="Color"
          placeholder="Color"
          bordered
          value={color.inputValue}
          onChange={color.inputHandler}
          onBlur={color.blurHandler}
          type="text"
        />
      </Grid> */}
      {type === "iWatch" && (
        <Grid xs={12} md={4}>
          <Input
            fullWidth
            label="Size"
            placeholder="Size"
            bordered
            value={size.inputValue}
            onChange={size.inputHandler}
            onBlur={size.blurHandler}
            type="text"
          />
        </Grid>
      )}
      <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="Quantity"
          placeholder="Quantity"
          bordered
          value={quantity.inputVale}
          onChange={quantity.inputHandler}
          onBlur={quantity.blurHandler}
          type="number"
        />
      </Grid>
      <Grid xs={12} md={4}>
        <Input
          fullWidth
          label="Price"
          placeholder="Price"
          bordered
          value={price.inputVale}
          onChange={price.inputHandler}
          onBlur={price.blurHandler}
          type="number"
        />
      </Grid>
      <Grid xs={12} md={4} D>
        <Input
          fullWidth
          label="My Price"
          placeholder="My Price"
          bordered
          value={myPrice.inputVale}
          onChange={myPrice.inputHandler}
          onBlur={myPrice.blurHandler}
          type="number"
        />
      </Grid>

      <Grid xs={12}>
        <Grid.Container gap={1}>
          {variants?.map((doc, i) => {
            return (
              <Grid xs={6} md={3} key={i}>
                <Item doc={doc} remove={() => removeHandler(i)} />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
      <Button
        css={{ my: 5, ml: 5 }}
        disabled={
          !storage.isValid ||
          (type === "iWatch" ? !size.isValid : false) ||
          !quantity.isValid ||
          !myPrice.isValid ||
          !price.isValid
        }
        onClick={addHandler}
      >
        Add
      </Button>
    </>
  );
};

const Item = ({ doc, remove }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "5px 10px",
        background: "#f8f8f8",
        borderRadius: "6px",
        width: "100%",
      }}
    >
      <Text>{doc?.type ? `${doc.type} - ${doc.size}` : doc.storage}</Text>
      <FiX onClick={remove} style={{ cursor: "pointer" }} />
    </div>
  );
};

export default Variants;
